<template>
  <FilterForm
    ref="FilterForm"
    @request="onSubmit"
    @reset="onReset"
  >
    <VInputDate
      ref="VInputDate"
      label="По дате"
    />

    <VSelect
      label="По ответственному:"
      name="user"
      :options="users"
    />

    <VSelect
      label="По событию:"
      name="event"
      :options="events"
    />
  </FilterForm>
</template>

<script>
import FilterForm from '@/components/Form/FilterForm'
import VInputDate from '@/components/Form/VInputDate.vue'
import VSelect from '@/components/Form/VSelect'
import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import { mapState } from 'vuex'
import { transformDateForSend } from '@/utils/form/date'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { changelogModel } from '@/entities/changelog'

export default {
  components: {
    VInputDate,
    VSelect,
    FilterForm
  },
  mixins: [mixinFilterEmit],

  computed: {
    ...mapState('users', {
      users: 'all'
    }),

    events: () => changelogModel.EVENTS.slice(0, 3)
  },

  methods: {
    onSubmit (data) {
      const preparedData = prepareFormDataId(data, ['user'])

      if (preparedData.event) {
        preparedData.event = preparedData.event.value
      }

      this.request({
        ...preparedData,
        ...transformDateForSend(this.$refs.VInputDate.$refs.dateRef?.defaultValue)
      })
    },

    onReset () {
      this.$refs.FilterForm.$refs.form.clear()
      this.$refs.VInputDate.$refs.dateRef._flatpickr.clear()

      this.reset()
    }
  }
}
</script>
