<template>
  <div>
    <h1 class="mb-m">Журнал изменений</h1>

    <ReclamationExternalFilterChangelog
      class="mb-m"
      @request="loadFilter"
      @reset="resetFilter"
    />

    <AppTable
      :columns="tableHead"
      :items="rows"
      :loading="isLoading"
      rowActive
      @rowClick="openDetails"
    />

    <AppPagination
      v-if="pagination.pageCount > 1"
      :current-page="pagination.page"
      :page-count="pagination.pageCount"
      @setPage="setPage"
    />

    <BaseModal
      v-if="isModal"
      title="Изменения"
      class="modal-changelog"
      @close="closeModal"
    >
      <AppTable
        class="modal__table modal__box custom-scroll"
        :columns="modalTh"
        :items="modalItems"
      />
    </BaseModal>
  </div>
</template>

<script>
import { ReclamationExternalFilterChangelog } from '@/features/reclamation-external/filter-changelog'
import AppPagination from '@/components/ui/AppPagination.vue'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import AppTable from '@/components/ui/AppTable.vue'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import { ChangelogApi } from '@/api/changelog/ChangelogApi'
import { changelogModel } from '@/entities/changelog'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { getPageCount } from '@/utils/store/pagination'
import { showAlertError } from '@/utils/store/alert'

export default {
  name: 'ReclamationChangelogPage',
  components: {
    AppTable,
    BaseModal,
    AppPagination,
    ReclamationExternalFilterChangelog
  },

  mixins: [mixinModal, mixinIsLoading],

  data: () => ({
    rows: [],
    modalItems: [],
    filter: {},
    pagination: {
      perPage: 20,
      page: 1,
      pageCount: 1
    }
  }),

  computed: {
    tableHead: () => changelogModel.TABLE_HEAD,
    modalTh: () => changelogModel.MODAL_TH
  },

  methods: {
    loadFilter (data) {
      this.filter = data
      this.setPage(1)
      this.getChangelogAll()
    },
    resetFilter () {
      this.filter = {}
      this.setPage(1)
      this.getChangelogAll()
    },

    openDetails (item) {
      this.modalItems = changelogModel.mapItemToDetails(item)
      this.openModal()
    },

    loadPage (num) {
      this.setPage(num)
      this.getChangelogAll()
    },
    setPage (num) {
      this.pagination.page = num
    },

    async getChangelogAll () {
      try {
        this.startLoading()
        this.resetRows()

        const response = await ChangelogApi.getAll({
          'per-page': this.pagination.perPage,
          page: this.pagination.page,
          ...this.filter,
          subject_type: reclamationExternalModel.CHANGELOG_SUBJECT_TYPE
        })

        this.setRows(response.data)
        this.pagination.pageCount = getPageCount(response)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    setRows (data) {
      this.rows.push(...data.map(changelogModel.mapData))
    },

    resetRows () {
      this.rows.length = 0
    }
  }
}
</script>
